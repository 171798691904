<div style="padding: 40px">
  <mat-card appearance="outlined" style="max-width: 300px; margin: auto">
    <mat-card-header>
      <mat-card-title i18n>Page doesn't exist.</mat-card-title>
      <mat-card-subtitle i18n></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p i18n>
        The owner may have change the link or deleted the booking page.
      </p>
      <p>
        <a [routerLink]="homepage" i18n>Create your own free Booking Page</a>
      </p>
      <a [routerLink]="homepage">
        <div class="text-center">
          <img
            alt="company logo"
            src="../../assets/images/Calendr logo 21 purple.svg"
            width="200"
          />
        </div>
      </a>
    </mat-card-content>
  </mat-card>
</div>
