import { computed, Injectable, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { AppSessionService } from "@shared/session/app-session.service";

import { FormControl } from "@angular/forms";
import { IDurationPackageInput } from "@shared/service-proxies/AdminEventType-client";
import {
  AttendanceDto,
  CalendarServiceProxy,
  CalendarSlot,
  GetSlotsOutput,
  ICalendarSlot,
} from "@shared/service-proxies/Calendar-client";
import {
  GuestCalendarServiceProxy,
  IGetOwnerCalendarInfoOutput,
} from "@shared/service-proxies/GuestCalendar-client";
import { isSameMonth, startOfDay } from "date-fns";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { EmbedService } from "./embed-popup/embed.service";
import { GuestAddMeetingDialogComponent } from "./guest-add-meeting-dialog/guest-add-meeting-dialog.component";

@Injectable()
export class GuestPageService {
  public previewMode = false;
  public selectedDuration$ = new FormControl<IDurationPackageInput>(null);
  public viewPeriod$ = new BehaviorSubject<{ start: Date; end: Date }>({
    // start of this month and end of this month
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });
  public calendarInfo$ =
    new BehaviorSubject<IGetOwnerCalendarInfoOutput | null>(null);
  public selectedSlot: ICalendarSlot;
  public viewDate$ = signal(new Date()); // we should use a formcontrol here but matcalendar doesn't support it
  public availableSlots$ = signal<CalendarSlot[]>([]);
  public thismonthsslots = new BehaviorSubject<CalendarSlot[]>([]);
  public availableSlotsOnDay$ = computed(() => {
    return this.availableSlots$()
      .filter((s) => s.startTime.isSameDayAs(this.viewDate$()))
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  });
  private GetSlotsFn: (
    duration: number,
    viewPeriod: { start: Date; end: Date },
    data: IGetOwnerCalendarInfoOutput
  ) => Observable<GetSlotsOutput>;
  public tfhours = false; //display in 24 hours
  public showIntraday = false; // mobile

  get isMyCalendar(): Observable<boolean> {
    return this.session.user$.pipe(
      map((user) => user.id == this.calendarInfo$.value.ownerId)
    );
  }
  ready$ = new BehaviorSubject<Boolean>(false);
  constructor(
    public service: GuestCalendarServiceProxy,
    public router: Router,
    public calendarServiceProxy: CalendarServiceProxy,
    public dialog: MatDialog,
    public session: AppSessionService,
    public calManager: EmbedService,
    public route: ActivatedRoute
  ) {
    // here we check for sample
    this.route.queryParamMap.subscribe((params) => {
      var v = params.get("sample");
      if (v) {
        this.previewMode = true;
      }
    });
    // in this situation only when the input changes do we need to go ahead and remake everything.
    combineLatest({
      duration:
        // will not output until all are ready
        this.selectedDuration$.valueChanges,
      viewPeriod: this.viewPeriod$.pipe(
        distinctUntilChanged((x, y) => {
          return x.start.valueOf() == y.start.valueOf();
        })
      ),
      calinfo: this.calendarInfo$,
      ready: this.ready$,
    })
      .pipe(
        // get the slots
        switchMap((d) =>
          !d.ready || !d.calinfo
            ? of(<CalendarSlot[]>[])
            : this.GetSlotsFn(d.duration.minutes, d.viewPeriod, d.calinfo).pipe(
                map((slots) => slots.slots)
              )
        )
      )
      .subscribe((slots) => {
        // interpret slots as utc
        slots.forEach((s) => {
          s.startTime = new Date(s.startTime);
          s.endTime = new Date(s.endTime);
        });

        // when we get new slots, change the current selected day if possible
        this.availableSlots$.set(slots);
        this.thismonthsslots.next(
          slots.filter((s) =>
            isSameMonth(this.viewPeriod$.value.start, s.startTime)
          )
        );
        if (this.thismonthsslots.value.length > 1) {
          this.viewDate$.set(
            startOfDay(this.thismonthsslots.value[0].startTime)
          );
        }
      });

    // set duration
    this.calendarInfo$.subscribe(
      //tap((ci) => console.debug("slots: calendar info changed"))
      (ci) => {
        console.debug("cosntructor: calendar info changed");
        if (this.calendarInfo$.value) {
          // we don't want to emit here because it is first load, only when the user changes it.
          this.selectedDuration$.setValue(ci.durationPackages[0], {
            emitEvent: true,
          });
        }
      }
    );
  }

  //We set the slots function and then refresh to tell the other parts it may get data
  setSlotsFn(
    fn: (
      duration: number,
      viewPeriod: { start: Date; end: Date },
      data: IGetOwnerCalendarInfoOutput
    ) => Observable<GetSlotsOutput>
  ): void {
    this.GetSlotsFn = fn;
    console.debug("setting slots function");
    this.ready$.next(true);
  }
  // getMoreBookings() {
  //   this.calManager.getMoreBookings(this.calendarInfo$.value.url);
  // }

  onCreateSchedule(event: CalendarSlot) {
    this.selectedSlot = {
      endTime: event.endTime,
      startTime: event.startTime,
    };
    const dialogRef = this.dialog.open(GuestAddMeetingDialogComponent, {
      maxWidth: 500,
      width: "100%",
      hasBackdrop: true,
      data: { service: this },
    });

    dialogRef.afterClosed().subscribe((result: AttendanceDto) => {
      if (result) {
        this.router.navigate(["confirmed", result.id]);
      }
    });
  }
}
