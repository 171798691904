<div
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
  "
>
  @if (service.availableSlotsOnDay$().length > 0) {
  <h2 class="text-with-icon">
    <mat-icon inline>access_time</mat-icon>
    <span i18n> Choose a time </span>
  </h2>
  <small style="text-align: center"
    >{{ timezone | timezone }} ({{ timezoneoffsethours >= 0 ? "+" : ""
    }}{{ timezoneoffsethours }} hours)
  </small>
  <div class="column-wrapper">
    <div *ngIf="availableAMSlotsOnDay$().length > 0" class="button-column">
      <button
        type="button"
        mat-stroked-button
        *ngFor="let event of availableAMSlotsOnDay$()"
        (click)="service.onCreateSchedule(event)"
      >
        {{ event.startTime | date : (service.tfhours ? "H:mm" : "h:mm a") }}
      </button>
    </div>
    <div *ngIf="availablePMSlotsOnDay$().length > 0" class="button-column">
      <button
        type="button"
        mat-stroked-button
        *ngFor="let event of availablePMSlotsOnDay$()"
        (click)="service.onCreateSchedule(event)"
      >
        {{ event.startTime | date : (service.tfhours ? "H:mm" : "h:mm a") }}
      </button>
    </div>
  </div>
  } @else {

  <h2 class="text-center" i18n>Please select a date</h2>
  <p i18n>No slots available this day.</p>
  <button class="mobile-only" mat-button (click)="service.showIntraday = false">
    <mat-icon>arrow_back</mat-icon>
  </button>
  }
</div>
