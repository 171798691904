import { DOCUMENT } from "@angular/common";
import { inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  getAllSysVariablesCSS,
  getColorPalettes,
  getMaterialDynamicScheme,
} from "@themecolor/index_bundled.js";

export const DEFAULT_COLOR = "#8714fa";

type WithStylesheet = typeof globalThis & {
  [stylesheetName: string]: CSSStyleSheet | undefined;
};

let enabled = true;

@Injectable({
  providedIn: "root",
})
export class ThemeSetterService {
  #document = inject(DOCUMENT);
  private primaryColor = "#87136e";
  private darkmode = "light dark";

  constructor(public route: ActivatedRoute) {
    //we should perhaps be using settings instead, or query parameters, but we will leave this here since some people have already embedded
    this.route.fragment.subscribe((param: string) => {
      if (param && param.startsWith("color=")) {
        // update primary color
        this.primaryColor = param.substr(6);
        // regenerate
        this.generateThemeAndApplyCssFromngThemeColor();
      }
    });

    // here we write up a dark mode
    this.route.queryParamMap.subscribe((params) => {
      var v = params.get("darkmode");
      // update darkmode
      if (v == "true") {
        this.darkmode = "dark";
      } else {
        this.darkmode = "light";
      }
      // regenerate
      this.generateThemeAndApplyCssFromngThemeColor();
    });
  }
  setDefaultTheme() {
    //this.setTheme("#87136e", "#bf197d");
    if (enabled) {
      this.primaryColor = "#87136e";
      this.generateThemeAndApplyCssFromngThemeColor();
    }
  }

  setTheme(primaryColor: string, secondaryColor: string) {
    if (enabled) {
      this.primaryColor = primaryColor;
      this.generateThemeAndApplyCssFromngThemeColor();
    }
  }

  generateThemeAndApplyCssFromngThemeColor() {
    const colorPalettes = getColorPalettes(
      this.primaryColor,
      "",
      "",
      "",
      "",
      ""
    );
    let themeCss = "";

    // generate light and dark theme together on the light-dark function
    themeCss += getAllSysVariablesCSS(
      getMaterialDynamicScheme(
        colorPalettes.primary,
        colorPalettes.secondary,
        colorPalettes.tertiary,
        colorPalettes.neutral,
        colorPalettes.neutralVariant,
        /* isDark */ false,
        /* contrastLevel */ 0
      ),
      getMaterialDynamicScheme(
        colorPalettes.primary,
        colorPalettes.secondary,
        colorPalettes.tertiary,
        colorPalettes.neutral,
        colorPalettes.neutralVariant,
        /* isDark */ true,
        /* contrastLevel */ 0
      )
    );

    // ONLY output pallettes, excluding light/dark setting (since we set it manually), exclude the typography system variables
    themeCss = themeCss.substring(
      themeCss.indexOf("/* Primary palette variables */"),
      themeCss.indexOf("/* TYPOGRAPHY SYSTEM VARIABLES")
    );

    // apply light or dark mode if set

    themeCss = `html { color-scheme: ${this.darkmode}; ${themeCss} } `;

    this.applyThemeString(themeCss, "angular-material-theme");
  }

  applyThemeString(themeString: string, ssName = "angular-material-theme") {
    let sheet = (globalThis as WithStylesheet)[ssName];

    if (!sheet) {
      sheet = new CSSStyleSheet();
      (globalThis as WithStylesheet)[ssName] = sheet;
      this.#document.adoptedStyleSheets.push(sheet);
    }

    sheet.replaceSync(themeString);
  }
}
