import {
  AfterViewInit,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AnswerType } from "@app/features/event-types/calendar-manager-edit/AnswerTypes";
import { GuestPageService } from "@app/guest-page.service";
import { StripeCurrencyService } from "@app/stripe-currency.service";
import { environment } from "@environments/environment";
import { AppComponentBase } from "@shared/app-component-base";
import { guessTimeZone } from "@shared/helpers/TimeZoneHelpers";

import { loadStripe } from "@stripe/stripe-js/pure";

import { CdkScrollable } from "@angular/cdk/scrolling";
import { AsyncPipe, DatePipe, DecimalPipe, NgFor, NgIf } from "@angular/common";

import { MatButton } from "@angular/material/button";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { TimezonePipe } from "@app/timezone.pipe";
import {
  CalendarOptions,
  LocationSettingEnum,
} from "@shared/service-proxies/AdminEventType-client";
import { AttendanceDto } from "@shared/service-proxies/Calendar-client";
import {
  GetAttendanceDetailsOutput,
  GuestAddMeetingInput,
  GuestCalendarServiceProxy,
  NewMeetingDetails,
} from "@shared/service-proxies/GuestCalendar-client";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";
import { Subscription } from "rxjs";

@Component({
  selector: "app-guest-add-meeting-dialog",
  templateUrl: "./guest-add-meeting-dialog.component.html",
  styleUrls: ["./guest-add-meeting-dialog.component.scss"],
  providers: [StripeCurrencyService],
  imports: [
    MatDialogTitle,
    NgIf,
    CdkScrollable,
    MatDialogContent,
    FormsModule,

    ReactiveFormsModule,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatRadioGroup,
    NgFor,
    MatRadioButton,
    MatSelect,
    MatOption,
    MatButton,
    MatTooltip,
    Angular2PromiseButtonModule,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    TimezonePipe,
  ],
})
export class GuestAddMeetingDialogComponent
  extends AppComponentBase
  implements OnInit, AfterViewInit
{
  public nameFormControl = new FormControl("", Validators.required);
  public emailFormControl = new FormControl("", Validators.required);
  public reasonFormControl = new FormControl(""); // for reschedule
  public locationFormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
  ]);
  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  public newMeetingForm: FormGroup;
  public guestQuestionsFormArray: FormArray = new FormArray([]);

  cardOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };
  cardValid = false;
  // remove "pure" to in the import and switch these types back to confirm
  card: any; //StripeCardElement; we don't load this type so we save some js size
  carderror: string;
  stripejs: any; //Stripe;  we don't load this type so we save some js size

  constructor(
    public guestCalendarProxyService: GuestCalendarServiceProxy,
    public router: Router,
    public dialogRef: MatDialogRef<GuestAddMeetingDialogComponent>,
    public stripeCurrencyService: StripeCurrencyService,
    @Inject(MAT_DIALOG_DATA) public data: { service: GuestPageService },
    @Inject(LOCALE_ID) public localeid: string
  ) {
    super();
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
  public checkboxAnswers = new Set<string>();

  async ngAfterViewInit() {
    if (this.data.service.calendarInfo$.value.paymentAccountId) {
      this.stripejs = await loadStripe(environment.stripePublishableKey, {
        stripeAccount:
          this.data.service.calendarInfo$.value.paymentAccountExternalId,
      });
      var elements = this.stripejs.elements();

      if (!this.card) {
        this.card = elements.create("card", this.cardOptions);
        this.card.mount("#example3-card");
      }

      this.card.on("change", (e) => {
        console.debug(e.error?.message);
        this.carderror = e.error?.message;
        this.cardValid = e.error || !e.complete ? false : true;
      });
    }
  }

  ngOnInit() {
    this.reasonFormControl.disable();

    this.newMeetingForm = new FormGroup({
      nameFormControl: this.nameFormControl,
      emailFormControl: this.emailFormControl,
      locationFormControl: this.locationFormControl,
      reasonFormControl: this.reasonFormControl,
      GuestQuestions: this.guestQuestionsFormArray,
    });
    if (
      !(
        this.data.service.calendarInfo$.value.calendarOptions.locationSetting ==
        LocationSettingEnum._1
      )
    ) {
      this.locationFormControl.disable();
    }

    if (
      this.data.service.calendarInfo$.value.calendarOptions.guestQuestions
        .length > 0
    ) {
      this.data.service.calendarInfo$.value.calendarOptions.guestQuestions.forEach(
        (q) => {
          /*********** set up new answer group *********/
          let freeAnswer = new FormControl("");
          let checkboxAnswers = new FormControl([]);
          let answersForExport = new FormControl(
            [],
            q.required ? [Validators.required, Validators.minLength(1)] : []
          );

          let newanswerformgroup = new FormGroup({
            freeAnswer: freeAnswer,
            checkboxAnswers: checkboxAnswers,
            answersForExport: answersForExport,
          });
          let updateAnswer = () => {
            let answers = <string[]>checkboxAnswers.value;
            let freeanswer = <string>freeAnswer.value;
            let result = new Array<string>();
            if (answers) result.addRange(answers);
            if (freeanswer) result.push(freeanswer);
            answersForExport.setValue(result);
          };

          freeAnswer.valueChanges.subscribe((v) => updateAnswer());
          checkboxAnswers.valueChanges.subscribe((V) => updateAnswer());

          if (q.answerType == AnswerType.Checkboxes && !q.freeChoice)
            freeAnswer.disable();

          this.guestQuestionsFormArray.push(newanswerformgroup);
          /*********** set up new answer group *********/
        }
      );
    }

    // if (this.data.previousMeetingDetails) {
    //     this.reasonFormControl.enable();
    // }
  }

  getNewMeetingDetails() {
    return new NewMeetingDetails({
      attendeeEmail: this.emailFormControl.value,
      attendeeName: this.nameFormControl.value,
      start: this.data.service.selectedSlot.startTime,
      timeZone: guessTimeZone(),
      location: this.locationFormControl.value,
      duration: this.data.service.selectedDuration$.value.minutes,
      language: this.localeid,
    });
  }

  getGuestQuestionAnswers(): string[][] {
    let res = new Array<Array<string>>();
    this.data.service.calendarInfo$.value.calendarOptions.guestQuestions.forEach(
      (v, i) => {
        res.push(
          this.guestQuestionsFormArray.controls[i].get("answersForExport").value
        );
      }
    );

    return res;
  }

  submittedCallback(d: AttendanceDto) {
    // success - goto viral page
    this.dialogRef.close(d);
    // add the event into the service
    //window.location.href = environment.hostname + '/attendance/' + d.id + '/confirmed'
  }

  //add event, which should also add it to the users calendar.
  //get the data from the view and forward it to the service.

  //we need to create and save the input on the backend upon getting payment request, to ensure the request is linked to the
  // payment properly(otherwise someone can manipulate the request to get a cheaper price.)
  submitPromise: Subscription;
  submitting = false;
  onSubmit() {
    if (
      this.submitting ||
      !this.newMeetingForm.valid ||
      (this.data.service.calendarInfo$.value.paymentAccountId &&
        !this.cardValid)
    )
      return;
    this.submitting = true;
    // try to book
    this.submitPromise = this.guestCalendarProxyService
      .createGuestMeeting(
        new GuestAddMeetingInput({
          newMeetingDetails: this.getNewMeetingDetails(),
          url: this.data.service.calendarInfo$.value.url,
          guestQuestionAnswersInput: this.getGuestQuestionAnswers(),
        })
      )
      .subscribe((d) => {
        // check if it was done, or needs payment
        if (d.attendance) {
          //done
          this.submittedCallback(d.attendance);
        } else {
          //needs payment
          this.stripejs
            .confirmCardPayment(d.paymentIntentId, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.nameFormControl.value,
                  email: this.emailFormControl.value,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                this.error(result.error.message);
                this.submitting = false;
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === "succeeded") {
                  this.guestCalendarProxyService
                    .tryCompletePaidBooking(result.paymentIntent.id)
                    .subscribe(
                      (d) => this.submittedCallback(d),
                      this.handleError
                    );
                  // Show a success message to your customer
                  // There's a risk of the customer closing the window before callback
                  // execution. Set up a webhook or plugin to listen for the
                  // payment_intent.succeeded event that handles any business critical
                  // post-payment actions.
                }
              }
            }, this.handleError);
        }
      });
  }

  handleError(e) {
    this.error(e.message);
    this.submitting = false;
  }
}

export class AddOrRescheduleMeetingModelData {
  startTime: Date;
  url: string;
  calendarOptions: CalendarOptions;
  duration: number;
  // optional for reschedule
  meetingId: string;
  previousMeetingDetails: GetAttendanceDetailsOutput;
}
