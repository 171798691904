import { Routes } from "@angular/router";
import { GuestPageResolver } from "@app/GuestPage.resolver";
import { ErrorComponent } from "@app/error/error.component";
import { GuestConfirmedComponent } from "@app/guest-confirmed/guest-confirmed.component";
import { GuestPageComponent } from "@app/guest-page/guest-page.component";
import { environment } from "@environments/environment";
import { AppComponent } from "./app.component";

// These routes are intended to be used by guests only. People who don't need to log in.

export const approutes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      {
        path: "error",
        component: ErrorComponent,
      },
      {
        path: "",
        redirectTo: environment.hostname,
        pathMatch: "full",
      },
      {
        path: ":url",
        component: GuestPageComponent,
        resolve: { data: GuestPageResolver },
      },
      {
        path: "confirmed/:id",
        component: GuestConfirmedComponent,
      },
      {
        path: "attendance/:id/cancel",
        loadComponent: () =>
          import("@app/guest-cancel/guest-cancel.component").then(
            (m) => m.GuestCancelComponent
          ),
      },
    ],
  },
];
