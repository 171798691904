import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { OwnerCalendarDto } from "@shared/service-proxies/AdminEventType-client";
import { EmbedPopupComponent } from "./embed-popup.component";

@Injectable({
  providedIn: "root",
})
export class EmbedService {
  constructor(private matDialog: MatDialog) {}

  // openEmailSigs(calurl: string) {
  //   this.matDialog.open(EmailSigsDialogComponent, {
  //     width: "500px",
  //     data: calurl,
  //   });
  // }

  openWidgetDialog(data: OwnerCalendarDto) {
    this.matDialog.open(EmbedPopupComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      data: data,
    });
  }
  // getMoreBookings(calurl: string) {
  //   this.matDialog.open(EducationDialogComponent, {
  //     width: '745px',
  //     data: <EducationDialogComponentData> { url: calurl, service: this }
  //   })
  // }
}
