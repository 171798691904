import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@environments/environment";
import { AppComponentBase } from "@shared/app-component-base";

import { NgIf } from "@angular/common";
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from "@angular/material/card";
import {
  GetAttendanceDetailsOutput,
  GuestCalendarServiceProxy,
} from "@shared/service-proxies/GuestCalendar-client";
import { EventDetailsComponent } from "../event-details/event-details.component";
import { GuestAdComponent } from "../guest-ad/guest-ad.component";

@Component({
  selector: "app-guest-confirmed",
  templateUrl: "./guest-confirmed.component.html",
  styleUrls: ["./guest-confirmed.component.scss"],
  imports: [
    NgIf,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    EventDetailsComponent,
    GuestAdComponent,
  ],
})
export class GuestConfirmedComponent
  extends AppComponentBase
  implements OnInit
{
  public attendance: GetAttendanceDetailsOutput;

  constructor(
    public route: ActivatedRoute,
    public serv: GuestCalendarServiceProxy
  ) {
    super();
  }

  ngOnInit() {
    ////// for routed param/////////
    this.route.paramMap.subscribe((params) =>
      this.serv
        .getAttendanceDetails(params.get("id"))
        .subscribe((r) => (this.attendance = r))
    );
  }
  homepage = environment.loginpage;
}
