import { enableProdMode, mergeApplicationConfig } from "@angular/core";
import { environment } from "@env/environment";

// Extensions must be imported here even though they are not directly used in this file.
import { bootstrapApplication } from "@angular/platform-browser";
import { browserConfig } from "projects/home/src/shared.browser-config";
import { getAppConfig } from "projects/home/src/shared.main";
import { AppComponent } from "./app/app.component";
import { approutes } from "./app/app.routes";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(
  AppComponent,
  mergeApplicationConfig(getAppConfig(approutes), browserConfig)
);
