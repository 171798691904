<div class="text-center">
  <h2 mat-dialog-title class="modal-title w-100" id="myModalLabel" i18n>
    Booking Details
  </h2>
</div>
<mat-dialog-content *ngIf="data.service.calendarInfo$ | async as caldata">
  <form
    #meetingForm="ngForm"
    [formGroup]="newMeetingForm"
    style="
      gap: 8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: stretch;
    "
    (ngSubmit)="
      newMeetingForm.updateValueAndValidity();
      newMeetingForm.valid ? onSubmit() : null
    "
  >
    <!--basic info-->
    <div
      style="
        gap: 8px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      "
    >
      <strong>{{ caldata.name }}</strong>
      <div
        *ngIf="caldata.paymentAccountId"
        style="
          gap: 8px;
          display: flex;
          flex-flow: row;
          justify-content: start;
          align-items: center;
        "
      >
        <mat-icon class="icon-suffix">credit_card</mat-icon>
        <span
          >{{
            !stripeCurrencyService.isZeroDecimalCurrency(
              caldata.paymentCurrency
            )
              ? (data.service.selectedDuration$.value?.paymentAmount
                | number : ".2")
              : data.service.selectedDuration$.value?.paymentAmount
          }}
          &nbsp;{{ caldata.paymentCurrency }}</span
        >
      </div>
      <div>
        <strong>
          {{ data.service.selectedSlot.startTime | date }}
          ({{ data.service.selectedSlot.startTime | date : "EEEE" }})
        </strong>
      </div>
      <div id="time-detail">
        <strong>
          {{
            data.service.selectedSlot.startTime
              | date : (data.service.tfhours ? "H:mm" : "h:mm a")
          }}
          -
          {{
            data.service.selectedSlot.endTime
              | date : (data.service.tfhours ? "H:mm" : "h:mm a")
          }}
          ({{ data.service.selectedDuration$.value.minutes }} minutes)
        </strong>
      </div>
      <br />
      <div>
        <i class="fa fa-map-o" aria-hidden="true"></i> {{ timezone | timezone }}
      </div>
      <br />
    </div>
    <div
      style="
        gap: 8px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: stretch;
      "
    >
      <mat-form-field>
        <mat-label i18n>Your Full Name</mat-label>
        <input matInput required type="text" [formControl]="nameFormControl" />
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Your Email</mat-label>
        <input
          matInput
          email
          required
          type="email"
          [formControl]="emailFormControl"
        />
      </mat-form-field>
      <!--Location setting 0 - fixed, owner decided. -->
      <!--option 1,  invitee decides-->
      <ng-container *ngIf="caldata.calendarOptions.locationSetting == 1">
        <h3>
          {{ caldata.calendarOptions.guestChoosesLocationQuestionText }}
        </h3>
        <!--invitee uses free choice-->
        <ng-container *ngIf="caldata.calendarOptions.guestLocationFreeChoice">
          <mat-form-field>
            <input
              matInput
              required
              type="text"
              [formControl]="locationFormControl"
            />
            <mat-hint i18n>e.g. Cafe name, Maps link, etc</mat-hint>
          </mat-form-field>
        </ng-container>

        <!--- options -->

        <ng-container *ngIf="!caldata.calendarOptions.guestLocationFreeChoice">
          <mat-radio-group
            class="example-radio-group"
            aria-i18n-label
            label="Select an option"
            [formControl]="locationFormControl"
          >
            <mat-radio-button
              class="example-radio-button"
              *ngFor="
                let option of caldata.calendarOptions.guestLocationChoices
              "
              [value]="option"
            >
              {{ option }}
            </mat-radio-button>

            <!--option + free choice-->
            <mat-radio-button
              #otherradio
              [value]="freechoice.value"
              *ngIf="caldata.calendarOptions.guestLocationChoicesAllowOther"
            >
              <mat-form-field style="width: 100%">
                <mat-label i18n>Other</mat-label>
                <input
                  matInput
                  #freechoice
                  [disabled]="!otherradio.checked"
                  required
                  type="text"
                  (input)="locationFormControl.setValue(getValue($event))"
                />
                <mat-hint i18n>Cafe name, Maps link, etc</mat-hint>
              </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
      </ng-container>

      <div
        formArrayName="GuestQuestions"
        style="
          gap: 8px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: stretch;
        "
      >
        <ng-container
          *ngFor="
            let answerFormGroup of guestQuestionsFormArray.controls;
            let i = index
          "
        >
          <ng-container formGroupName="{{ i }}">
            <h4>{{ caldata.calendarOptions.guestQuestions[i].question }}</h4>
            <mat-form-field
              *ngIf="
                caldata.calendarOptions.guestQuestions[i].answerType ==
                'One Line'
              "
            >
              <mat-label i18n></mat-label>
              <input matInput formControlName="freeAnswer" />
            </mat-form-field>

            <mat-form-field
              *ngIf="
                caldata.calendarOptions.guestQuestions[i].answerType ==
                'Multiple Lines'
              "
            >
              <mat-label i18n></mat-label>
              <textarea
                matInput
                rows="5"
                formControlName="freeAnswer"
              ></textarea>
            </mat-form-field>

            <div
              *ngIf="
                caldata.calendarOptions.guestQuestions[i].answerType ==
                'Radio Buttons'
              "
            >
              <mat-radio-group
                class="example-radio-group"
                #otheranswerradio
                formControlName="freeAnswer"
                [required]="caldata.calendarOptions.guestQuestions[i].required"
              >
                <mat-radio-button
                  *ngIf="
                    caldata.calendarOptions.guestQuestions[i].required == false
                  "
                  value="No Answer"
                  class="example-radio-button"
                  ngModel
                  [ngModelOptions]="{ standalone: true }"
                >
                  <span i18n>No answer</span>
                </mat-radio-button>

                <mat-radio-button
                  [value]="option"
                  class="example-radio-button"
                  *ngFor="
                    let option of caldata.calendarOptions.guestQuestions[i]
                      .choices
                  "
                >
                  {{ option }}
                </mat-radio-button>

                <mat-radio-button
                  #answerotherradio
                  [value]="freechoice.value"
                  *ngIf="caldata.calendarOptions.guestQuestions[i].freeChoice"
                  class="example-radio-button"
                >
                  <mat-form-field>
                    <mat-label i18n>Other</mat-label>
                    <input
                      matInput
                      #freechoice
                      [disabled]="!answerotherradio.checked"
                      required
                      type="text"
                      formControlName="freeAnswer"
                    />
                  </mat-form-field>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div
              *ngIf="
                caldata.calendarOptions.guestQuestions[i].answerType ==
                'Check Boxes'
              "
            >
              <mat-form-field>
                <mat-label i18n>Select....</mat-label>
                <mat-select
                  formControlName="checkboxAnswers"
                  multiple
                  [required]="
                    !guestQuestionsFormArray.controls[i].get('answersForExport')
                      .valid
                  "
                >
                  <mat-option
                    *ngFor="
                      let option of caldata.calendarOptions.guestQuestions[i]
                        .choices
                    "
                    [value]="option"
                    >{{ option }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <mat-form-field
                *ngIf="
                  !guestQuestionsFormArray.controls[i].get('freeAnswer')
                    .disabled
                "
              >
                <mat-label i18n>Add Other</mat-label>
                <input
                  matInput
                  formControlName="freeAnswer"
                  [required]="
                    !guestQuestionsFormArray.controls[i].get('answersForExport')
                      .valid
                  "
                  type="text"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <!--/.Panel-->
        </ng-container>
      </div>
      <br />
      <div *ngIf="caldata.paymentAccountId">
        <div
          style="
            gap: 8px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: stretch;
          "
        >
          <ng-container *ngIf="caldata.paymentTerms">
            <h3 i18n>Payment Terms</h3>
            <div
              style="
                gap: 8px;
                display: flex;
                flex-flow: row;
                justify-content: start;
                align-items: start;
              "
            >
              <pre class="mat-typography">{{ caldata.paymentTerms }}</pre>
            </div>
          </ng-container>
          <div id="card-card" class="mat-elevation-z20">
            <div
              style="padding: 10px; margin-bottom: 2px"
              id="example3-card"
              class="field"
            >
              Loading secure input...
            </div>
          </div>
          <div
            style="
              gap: 8px;
              display: flex;
              flex-flow: row;
              justify-content: center;
              align-items: center;
            "
          >
            <mat-icon>lock</mat-icon>
            <small
              >We use the well-regarded
              <a href="https://stripe.com/" target="_blank">Stripe</a> to
              process payments.</small
            >
          </div>
          <br />
          <small>
            Disputes/refunds cannot be handled through Calendr, please contact
            the seller directly.</small
          >
          <div>{{ carderror }}</div>
          <br />
        </div>
      </div>
    </div>
    <button
      mat-flat-button
      [matTooltipDisabled]="!data.service.previewMode"
      matTooltip="Cannot book in preview!"
      [disabled]="
        submitting ||
        !newMeetingForm.valid ||
        (caldata.paymentAccountId && !cardValid)
      "
      [type]="data.service.previewMode ? 'button' : 'submit'"
      [promiseBtn]="submitting"
      aria-i18n-label
      label="Confirm"
      i18n
    >
      Confirm
    </button>
  </form>
</mat-dialog-content>
