import { DatePipe, NgFor, NgIf } from "@angular/common";
import { Component, computed } from "@angular/core";

import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GuestPageService } from "@app/guest-page.service";
import { TimezonePipe } from "@app/timezone.pipe";

@Component({
  selector: "app-intraday-time-selector",
  templateUrl: "./intraday-time-selector.component.html",
  styleUrls: ["./intraday-time-selector.component.scss"],
  imports: [NgIf, MatIcon, NgFor, MatButton, DatePipe, TimezonePipe],
})
export class IntradayTimeSelectorComponent {
  timezone: string;
  timezoneoffsethours: number;
  public availableAMSlotsOnDay$ = computed(() => {
    return this.service
      .availableSlotsOnDay$()
      .filter((s) => s.startTime.getHours() < 12);
  });
  public availablePMSlotsOnDay$ = computed(() => {
    return this.service
      .availableSlotsOnDay$()
      .filter((s) => s.startTime.getHours() >= 12);
  });

  constructor(public service: GuestPageService) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneoffsethours = -(new Date().getTimezoneOffset() / 60);
  }
}
