<!-- <button
  mat-button
  [matMenuTriggerFor]="menu"
  *ngIf="GuestPageService.isMyCalendar"
  style="position: absolute; top: 0; right: 0"
>
  <mat-icon>menu</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div style="width: 100%; text-align: center">
    <small>Guest's can't see this menu</small>
  </div>
  <button
    type="button"
    mat-menu-item
    (click)="GuestPageService.getMoreBookings()"
  >
    <mat-icon>share</mat-icon>
    Get more bookings
  </button>
</mat-menu> -->

<app-calendr> </app-calendr>
