<div style="max-width: 500px; height: 100vh; margin: auto" *ngIf="attendance">
  <div
    style="
      gap: 8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <mat-card
      appearance="outlined"
      style="
        gap: 8px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      "
    >
      <mat-card-header
        ><mat-card-title i18n
          >Booking Confirmed</mat-card-title
        ></mat-card-header
      >
      <mat-card-content>
        <span
          ><strong i18n
            >Your meeting with {{ attendance.hostFirstName }}
            {{ attendance.hostLastName }} is scheduled:
          </strong>
        </span>
        <br />

        <p i18n>We've sent you an email to help you manage your appointment.</p>

        <p i18n>
          For online meetings, there will be a meeting link in your email.
        </p>
        <mat-card appearance="outlined">
          <mat-card-content>
            <app-event-details [id]="attendance.id"></app-event-details>
          </mat-card-content>
        </mat-card>
        <app-guest-ad *ngIf="!attendance.hasPremium"></app-guest-ad>
      </mat-card-content>
    </mat-card>
  </div>
</div>
