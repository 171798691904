import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import {
  GetOwnerCalendarInfoOutput,
  GuestCalendarServiceProxy,
} from "@shared/service-proxies/GuestCalendar-client";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class GuestPageResolver implements Resolve<GetOwnerCalendarInfoOutput> {
  constructor(
    private service: GuestCalendarServiceProxy,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.service.getOwnerCalendarInfo(route.paramMap.get("url")).pipe(
      tap((e) => {
        if (!e.id) this.router.navigate(["error"]);
      })
    );
  }
}
