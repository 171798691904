import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from "@angular/router";
import { ThemeSetterService } from "@app/theme-setter.service";
import { environment } from "@environments/environment";
import { AppComponentBase } from "@shared/app-component-base";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  imports: [RouterOutlet],
})
export class AppComponent extends AppComponentBase implements OnInit {
  loading: boolean;
  constructor(
    private Router: Router,
    private meta: Meta,
    private themeService: ThemeSetterService // constructor will apply theme
  ) {
    super();
  }

  ngOnInit(): void {
    this.Router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        console.debug("Routing start");
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loading = false;
        console.debug("Routing end");
      }
    });

    if (!environment.production) {
      this.meta.addTag({ name: "robots", content: "noindex" });
    }
  }
}
