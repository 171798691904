import { Component } from "@angular/core";
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from "@angular/material/card";
import { RouterLink } from "@angular/router";
import { environment } from "@environments/environment";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    RouterLink,
  ],
})
export class ErrorComponent {
  constructor() {}

  ngOnInit(): void {}

  get homepage() {
    return environment.hostname;
  }
}
