import {
  AsyncPipe,
  DecimalPipe,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  signal,
  ViewChild,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatCardAvatar, MatCardModule } from "@angular/material/card";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatOption,
  provideNativeDateAdapter,
} from "@angular/material/core";
import { MatCalendar, MatDatepickerModule } from "@angular/material/datepicker";
import { MatDivider } from "@angular/material/divider";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { MatSelect } from "@angular/material/select";
import { CustomDateFormatter } from "@app/extended-angular-calendar/CustomerDateFormatter";
import { GuestPageService } from "@app/guest-page.service";
import { StripeCurrencyService } from "@app/stripe-currency.service";
import { AppComponentBase } from "@shared/app-component-base";
import { CalendarDateFormatter } from "angular-calendar";
import { endOfMonth, startOfDay, startOfMonth } from "date-fns";
import { Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from "rxjs/operators";
import { GuestAdComponent } from "../guest-ad/guest-ad.component";
import { IntradayTimeSelectorComponent } from "../intraday-time-selector/intraday-time-selector.component";

@Component({
  selector: "app-calendr",
  templateUrl: "./calendr.component.html",
  styleUrls: ["./calendr.component.scss"],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    provideNativeDateAdapter(),

    StripeCurrencyService,
  ],
  imports: [
    NgIf,
    NgTemplateOutlet,
    MatDivider,
    MatIconButton,
    MatIcon,
    GuestAdComponent,
    IntradayTimeSelectorComponent,
    MatCardAvatar,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    AsyncPipe,
    DecimalPipe,
    MatCardModule,
    MatDatepickerModule,
  ],
})
export class CalendrComponent
  extends AppComponentBase
  implements AfterViewInit
{
  calloading = true;
  @ViewChild(MatCalendar<Date>)
  calendar: MatCalendar<Date>;

  readonly exampleHeader = ExampleHeader;
  constructor(
    public service: GuestPageService,
    public stripeCurrencyService: StripeCurrencyService
  ) {
    super();
  }
  // function returns true means to show on the calendar
  myFilter$: Observable<(d: Date | null) => boolean> =
    // will be fired each time it needs to know about some dates
    this.service.thismonthsslots.pipe(
      map((slots) => (d: Date | null): boolean => {
        let sod = startOfDay(d).valueOf();
        let availableDays = new Set(
          slots.map((slot) => startOfDay(slot.startTime).valueOf())
        );
        return availableDays.has(sod);
      })
    );

  ngAfterViewInit() {
    // when month changes, load more events
    this.calendar.stateChanges
      .pipe(
        map(() => this.calendar.activeDate),
        distinctUntilChanged(
          (a, b) => startOfMonth(a).valueOf() == startOfMonth(b).valueOf()
        )
      )
      .subscribe((date) => this.activeDateChange(date)); // subscribing to the pipe

    this.service.thismonthsslots.subscribe((slots) => {
      // update active day
      if (slots.length > 0) this.calendar.activeDate = slots[0].startTime;
    });
  }

  activeDateChange(Date: any) {
    console.log("activeDateChange selected", Date);
    this.service.viewPeriod$.next({
      start: startOfMonth(Date),
      end: endOfMonth(Date),
    });
  }
}

/** Custom header component for datepicker.  stolen from docs*/
@Component({
  selector: "example-header",
  styles: `
    .example-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }
  `,
  template: `
    <div class="example-header">
      <button
        aria-label="previous month"
        mat-icon-button
        (click)="previousClicked('month')"
        type="button"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="example-header-label">{{ periodLabel() }}</span>
      <button
        aria-label="next month"
        mat-icon-button
        (click)="nextClicked('month')"
        type="button"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
  private _calendar = inject<MatCalendar<D>>(MatCalendar);
  private _dateAdapter = inject<DateAdapter<D>>(DateAdapter);
  private _dateFormats = inject(MAT_DATE_FORMATS);

  private _destroyed = new Subject<void>();

  readonly periodLabel = signal("");

  constructor() {
    this._calendar.stateChanges
      .pipe(startWith(null), takeUntil(this._destroyed))
      .subscribe(() => {
        this.periodLabel.set(
          this._dateAdapter
            .format(
              this._calendar.activeDate,
              this._dateFormats.display.monthYearLabel
            )
            .toLocaleUpperCase()
        );
      });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
