<div
  style="display: flex; flex-direction: column; align-items: center"
  id="embedsample"
>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title i18n>Website embed</mat-card-title>
      <mat-card-subtitle i18n>
        The website embed allows you to easily take bookings directly from your
        website.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p i18n>Simply copy and paste the below code into your site.</p>
      <div i18n>
        Primary Color
        <input type="color" [(ngModel)]="color" />
      </div>
      <div>
        <mat-checkbox class="example-margin" [(ngModel)]="dark" i18n
          >Dark Mode</mat-checkbox
        >
      </div>
      <textarea
        id="card1"
        class="mat-elevation-z8"
        style="cursor: pointer; width: 100%"
        (click)="copycode()"
        [value]="getEmbedCode()"
      >
      </textarea>
      <small i18n
        >Ask your developer to adjust the width/height to get it to fit
        perfectly on your site.</small
      >
      <div mat-dialog-actions align="center">
        <button mat-flat-button (click)="onNoClick()" i18n>Close</button>
        <button mat-flat-button (click)="copycode()" i18n>Copy</button>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-header
      ><mat-card-title i18n>Preview</mat-card-title></mat-card-header
    >
    <mat-card-content>
      <iframe
        #embedsampleiframe
        frameborder="0"
        [width]="width"
        [height]="height"
        [src]="
          meetinghostname +
            '/' +
            data.url +
            (this.dark ? '?darkmode=true' : '') +
            '#color=' +
            color | safe
        "
      ></iframe>
    </mat-card-content>
  </mat-card>
</div>
