import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendrComponent } from "@app/calendr/calendr.component";
import { EmbedService } from "@app/embed-popup/embed.service";
import { GuestPageService } from "@app/guest-page.service";
import { CalendarEvent } from "@shared/CalendarEvent";
import { AppComponentBase } from "@shared/app-component-base";
import { AttendanceDto } from "@shared/service-proxies/AdminEvent-client";
import {
  GuestCalendarServiceProxy,
  IGetOwnerCalendarInfoOutput,
} from "@shared/service-proxies/GuestCalendar-client";

import { Observable } from "rxjs";

@Component({
  selector: "app-guest-page",
  templateUrl: "./guest-page.component.html",
  styleUrls: ["./guest-page.component.scss"],
  providers: [GuestPageService, EmbedService],
  imports: [CalendrComponent],
})
export class GuestPageComponent extends AppComponentBase {
  public availableSlots$: Observable<CalendarEvent[]>;
  @ViewChild("addMeetingModal", { static: false }) public meetingmodal;
  @ViewChild("calendar", { static: false }) public calendar: CalendrComponent;
  public showCalendar = true;
  public startTime: Date = new Date();
  meetingDetails: AttendanceDto;
  ShowAd = true;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public guestCalendarProxyService: GuestCalendarServiceProxy,
    public dialog: MatDialog,
    public GuestPageService: GuestPageService,
    private titleService: Title,
    private meta: Meta
  ) {
    super();
    let od = <IGetOwnerCalendarInfoOutput>this.route.snapshot.data.data;
    this.ShowAd = od.hasPremium;
    this.GuestPageService.calendarInfo$.next(od);
    this.GuestPageService.setSlotsFn((duration, ViewPeriod, info) =>
      this.guestCalendarProxyService.getSlotsForId(
        ViewPeriod.start,
        ViewPeriod.end,
        duration,
        info.id
      )
    );
    this.titleService.setTitle(od.name + " | Calendr");
    this.meta.addTag({ name: "og:title", content: od.name + " | Calendr" });
    this.meta.addTag({
      name: "og:image",
      content: od.calendarOptions.profilePic,
    });
    this.meta.addTag({
      name: "og:description",
      content:
        "Say goodbye to wasted time with Calendr's appointment and meeting scheduling software. Create your free meeting scheduler now.",
    });
    //not needed
    //meta.addTag({name: "og:url", content: this.meetinghostname + od.url });
  }

  public freelocationchoice: string = "";
}
