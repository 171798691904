import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StripeCurrencyService {

  constructor() { }

  isZeroDecimalCurrency(cur: string) {
    const ZeroDecimalCurrencies = ["BIF",
      "CLP",
      "DJF",
      "GNF",
      "JPY",
      "KMF",
      "KRW",
      "MGA",
      "PYG",
      "RWF",
      "UGX",
      "VND",
      "VUV",
      "XAF",
      "XOF",
      "XPF"];

    return ZeroDecimalCurrencies.some(d => d == cur);
  }
}
