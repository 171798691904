export function guessTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  
  export function getUTCOffset(timeZone : string) {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = { timeZone, hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  
    const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }, , { value: second }] = dateTimeFormat.formatToParts(now);
  
    const formattedString = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    const dateInTimeZone = new Date(formattedString + 'Z');
  
    let offset = ((dateInTimeZone.getTime() - now.getTime()) / 60000) // UTC offset in minutes
// remove/round to nearest minute
    offset = Math.round(offset);
    return offset;
  }