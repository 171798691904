@let data = service.calendarInfo$ | async;

<div
  style="
    height: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
  "
>
  <ng-container *ngTemplateOutlet="sheader"></ng-container>

  <mat-divider></mat-divider>

  <div
    style="
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-height: 500px;
      min-width: 300px;
      justify-content: center;
      max-width: 700px;
    "
  >
    <div style="min-width: 300px; max-width: 700px; flex: 1">
      <h2 class="text-with-icon" style="justify-content: center">
        <mat-icon inline>event</mat-icon>
        <span i18n> Choose a date </span>
      </h2>
      <mat-card style="">
        <mat-calendar
          #calendar
          [(selected)]="service.viewDate$"
          [dateFilter]="myFilter$ | async"
          [headerComponent]="exampleHeader"
        ></mat-calendar>
      </mat-card>
    </div>
    <div style="max-height: 500px; flex: 1">
      <div class="back-button">
        <button
          type="button"
          mat-icon-button
          (click)="service.showIntraday = false"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>

      <app-intraday-time-selector> </app-intraday-time-selector>
    </div>
  </div>

  <app-guest-ad
    *ngIf="!data.hasPremium"
    style="margin-top: auto"
  ></app-guest-ad>

  <ng-template #sheader
    ><ng-container *ngIf="service.calendarInfo$ | async as data">
      <div
        style="
          padding: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          *ngIf="data.calendarOptions.profilePic"
          mat-card-avatar
          style="width: 80px; height: 80px; margin: 4px"
          alt="meeting event booking host owner profile picture"
          src="{{
            data.calendarOptions.profilePic
              ? data.calendarOptions.profilePic
              : '/assets/images/generic-profile-picture.webp'
          }}"
        />

        <h3>{{ data.calendarOptions.headline }}</h3>
        <h4 *ngIf="data.calendarOptions.subline !== ''">
          {{ data.calendarOptions.subline }}
        </h4>

        <h1>{{ data.name }}</h1>

        <div
          *ngIf="
            data.calendarOptions.showLocationBeforeConfirmation &&
            data.calendarOptions.locationSetting == 2 &&
            data.location
          "
        >
          <mat-icon inline class="icon-suffix">place</mat-icon>
          <span> {{ data.location }} </span>
        </div>
        <ng-container *ngIf="data.durationPackages.length > 1">
          <mat-form-field style="width: 250px">
            <mat-label i18n>Duration</mat-label>
            <mat-select
              [formControl]="service.selectedDuration$"
              name="duration"
            >
              @for (dp of data.durationPackages; track dp) {
              <mat-option [value]="dp"
                >{{ dp.minutes }} <span i18n> minutes</span>
                {{
                  !data.paymentAccountId
                    ? ""
                    : "- " + (dp.paymentAmount + " " + data.paymentCurrency)
                }}</mat-option
              >
              }
            </mat-select>
          </mat-form-field>
          <!-- 
        <a
          [matMenuTriggerFor]="menu"
          style="text-decoration: underline; cursor: pointer"
          i18n
          >change</a
        >
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            type="button"
            *ngFor="let o of data.durationPackages"
            [value]="o"
          >
            <span i18n>
              {{ o.minutes }} Minutes
              {{
                !data.paymentAccountId
                  ? ""
                  : "&nbsp;" + (o.paymentAmount + " " + data.paymentCurrency)
              }}
            </span>
          </button>
        </mat-menu> -->
        </ng-container>

        @if(data.durationPackages.length == 1) {

        <div>
          <mat-icon inline class="icon-suffix">access_time</mat-icon>
          <span i18n>
            {{ service.selectedDuration$.value.minutes }} minutes</span
          >
        </div>
        @if (data.paymentAccountId) {
        <div>
          <mat-icon inline class="icon-suffix">credit_card</mat-icon>
          <span>
            {{
              !stripeCurrencyService.isZeroDecimalCurrency(data.paymentCurrency)
                ? (service.selectedDuration$.value?.paymentAmount
                  | number : ".2")
                : service.selectedDuration$.value?.paymentAmount
            }}&nbsp;{{ data.paymentCurrency }}
          </span>
        </div>
        } }
        <ng-container *ngIf="data.calendarOptions.description">
          <pre><span class="mat-typography" style="white-space:pre-wrap;"> {{data.calendarOptions.description}} </span></pre>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>
