import { Clipboard } from "@angular/cdk/clipboard";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { SafePipe } from "@app/safe.pipe";
import { AppComponentBase } from "@shared/app-component-base";

import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from "@angular/material/card";
import { MatCheckbox } from "@angular/material/checkbox";
import { OwnerCalendarDto } from "@shared/service-proxies/AdminEventType-client";

@Component({
  selector: "app-embed-popup",
  templateUrl: "./embed-popup.component.html",
  styleUrls: ["./embed-popup.component.scss"],
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    FormsModule,
    MatCheckbox,
    MatDialogActions,
    MatButton,
    SafePipe,
  ],
})
export class EmbedPopupComponent extends AppComponentBase implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EmbedPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OwnerCalendarDto,
    private snackbar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private clipboard: Clipboard
  ) {
    super();
  }

  // capture iframe element so its width and height can be changed
  @ViewChild("embedsampleiframe", { read: ElementRef }) embedsample: ElementRef;

  height: number = 800;
  width: number = 800;
  color: string = "#87136e";
  dark = false;

  observer = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      console.log("width", entry.contentRect.width);
      console.log("height", entry.contentRect.height);
      this.embedsample.nativeElement.width = entry.contentRect.width - 20;
    });
  });

  ngOnInit() {
    this.observer.observe(document.querySelector("#embedsample"));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getEmbedCode() {
    return (
      "<iframe frameborder='0' width=" +
      this.width +
      " height=" +
      this.height +
      " src='" +
      this.getMeetingUrl() +
      "'></iframe>"
    );
  }

  getMeetingUrl() {
    return (
      this.meetinghostname +
      "/" +
      this.data.url +
      "?darkmode=" +
      (this.dark ? "true" : "false") +
      "#color=" +
      this.color
    );
  }
  copycode() {
    this.clipboard.copy(this.getEmbedCode());

    this.snackbar.open($localize`Copied to clipboard!`, null, {
      duration: 2000,
    });
  }
}
